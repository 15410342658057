import * as React from "react";
import { Parallax } from "react-parallax";
import { Link } from "gatsby";

import image from "../images/home.jpg";
import agreementSvg from "../images/agreement.svg";
import secureSvg from "../images/secure-server.svg";
import bbb from "../images/BBB.svg";
import trustpilot from "../images/trustpilot.png";
import Layout from "../components/layout";
import Iframe from "../components/iframe";
import Section from "../components/section";
import Typography from "../components/typography";
import { Container, Row, Modal } from "react-bootstrap";

const HomePage = () => {
  const [iframeOpen, setIframeOpen] = React.useState<boolean>(false);

  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section size="md" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
        
          <Container className="text-center">
            <Typography type="head" className="hide-on-mobile text-center">
              A Universal Funding Solution Right at Your Fingertips
            </Typography>
            <Typography type="head" className="hide-on-desktop text-center">
              A Funding Solution at your Fingertips
            </Typography>
            <hr style={{ height: "2px" }} className="mx-auto mt-4 mb-4" />
            <Modal
              show={iframeOpen}
              onHide={() => setIframeOpen(false)}
              size="xl"
              centered
            >
              <Modal.Header
                closeButton
                style={{ border: "none", paddingBottom: 0 }}
              />
              <Modal.Body style={{ paddingTop: 0 }}>
                <Iframe />
              </Modal.Body>
            </Modal>
            <Link to="/">
              <button className="btn-lg" onClick={() => setIframeOpen(true)}>
                Get Started
              </button>
            </Link>
            
          </Container> 
        
        </Parallax>
      </Section>
      <Section className="d-flex" size="sm" bg="dark" color="light">
        <Container className="d-flex justify-content-center flex-column flex-1" id="bankingverse-external-leads-widget">
          <Typography className="text-center" type="title">
            Join The Fundingverse Family
          </Typography>
          <Iframe />
        </Container>
      </Section>
      <Section size="xsm">
        <Row
          className="align-items-center justify-content-center h-100 w-"
          style={{ borderBottom: "1px solid black" }}
        >
          <div
            className="d-flex justify-content-center w-25 my-5"
            style={{ minWidth: "200px" }}
            onClick={() => {
              window.location.href =
                "https://www.bbb.org/us/ga/atlanta/profile/customer-relationship-management/fundingverse-inc-0443-28191812";
            }}
          >
            <img src={bbb} alt="BBB Accredited Business" height="75px" />
          </div>
          <div
            className="d-flex justify-content-center w-25 my-5"
            style={{ minWidth: "200px" }}
            onClick={() => {
              window.location.href =
                "https://www.trustpilot.com/review/fundingverse.com";
            }}
          >
            <img src={trustpilot} alt="Find us on Trustpilot" height="75px" />
          </div>
        </Row>
      </Section>
      <Section size="md">
        <Container className="d-flex flex-nowrap h-100 align-items-center h-100 py-5 nom">
          <div className="d-inline-block flex-full me-5">
            <img
              className="h-100"
              style={{ maxHeight: "400px" }}
              src={agreementSvg}
              alt="Two People Agreeing on Document Terms"
            />
          </div>
          <div className="d-inline-block">
            <Typography type="title">
              Get Immediate Funding on Our Platform
            </Typography>
            <ul>
              <li>
                A singular search process connecting your business to every type
                of lender you are eligible for!
              </li>
              <li>
                Our Job is to do all the work and get you funded, so you can do
                what you do best: Run your business.
              </li>
            </ul>
          </div>
        </Container>
        <Container className="hide-on-desktop py-5">
          <div className="d-flex justify-content-center mb-3">
            <img
              className="h-100"
              style={{ maxHeight: "300px" }}
              src={agreementSvg}
              alt="Two People Agreeing on Document Terms"
            />
          </div>
          <div className="mx-4">
            <Typography type="title">
              Get Immediate Funding on Our Platform
            </Typography>
            <ul>
              <li>
                A singular search process connecting your business to every type
                of lender you are eligible for!
              </li>
              <li>
                Our Job is to do all the work and get you funded, so you can do
                what you do best: Run your business.
              </li>
            </ul>
          </div>
        </Container>
      </Section>
      <Section size="md">
        <Container className="nom d-flex flex-nowrap h-100 align-items-center h-100 py-5">
          <div className="d-inline-block">
            <Typography type="title">
              Join our Secure Family of Funding
            </Typography>
            <ul>
              <li>
                Have a Secure Connection to Any Partnered Bank for All your
                Funding Needs.
              </li>
              <li>
                We are Partnered with Banks, Alternative Lenders, and other
                Funding Sources to Help The Businesses in our Communities.
              </li>
              <li>
                Maintain a Relationship With Your Bank even If they can’t Lend
                to you directly
              </li>
            </ul>
          </div>
          <div className="d-inline-block flex-full ms-5">
            <img
              className="h-100"
              style={{ maxHeight: "290px" }}
              src={secureSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
        </Container>
        <Container className="hide-on-desktop">
          <div className="mb-5">
            <img
              className="w-100"
              style={{ maxHeight: "220px" }}
              src={secureSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
          <div className="mx-4">
            <Typography type="title">
              Join our Secure Family of Funding
            </Typography>
            <ul>
              <li>
                Have a Secure Connection to Any Partnered Bank for All your
                Funding Needs.
              </li>
              <li>
                We are Partnered with Banks, Alternative Lenders, and other
                Funding Sources to Help The Businesses in our Communities.
              </li>
              <li>
                Maintain a Relationship With Your Bank even If they can’t Lend
                to you directly
              </li>
            </ul>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default HomePage;
